import React from "react";
import { IconButton as MUIButton, styled, SxProps, Theme } from "@mui/material";

interface ButtonProps {
  onClick: () => void;
  styles?: SxProps<Theme>; // Accept Material-UI `sx` styles for customization
  Icon?: React.ReactNode;
}

const StyledButton = styled(MUIButton)(({ theme }) => ({
  backgroundColor: "#000", // Red background
  color: "#fff", // White text
  "&:hover": {
    backgroundColor: "#e55", // Darker red on hover
  },
  padding: "8px",
}));

const IconButton: React.FC<ButtonProps> = ({ onClick, styles, Icon }) => {
  return (
    <StyledButton onClick={onClick} sx={styles}>
      {Icon}
    </StyledButton>
  );
};

export default IconButton;
