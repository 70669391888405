import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2"; // Correct import for Grid2
import Navbar from "../Shared/Navbar";
import AddressDetailsForm from "./AddressDetailsForm";
import CommentField from "./CommentField";
import IconButton from "../Shared/IconButton";
import CallEndIcon from "@mui/icons-material/CallEnd";
import Chip from "../Shared/Chip";
import Button from "../Shared/Button";
import TimerIcon from "@mui/icons-material/Timer";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Dashboard: React.FC = () => {
  const handleLogout = () => {
    console.log("Logout clicked");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Navbar />
      <Grid
        size={12}
        sx={{
          pt: 2,
          pr: 2,
          pb: 0,
          pl: 2,
        }}
      >
        <Item sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              label="INGET SVAR"
              variant="text"
              onClick={handleLogout}
              startIcon={<SpeakerNotesOffIcon />}
              styles={{
                background: "none",
                color: "#3c1329",
                "&:hover": {
                  backgroundColor: "#fff2f2",
                  fontWeight: 800,
                },
                mr: 2,
                width: "180px",
              }}
            />
            <Button
              label="FEL NUMMER"
              variant="text"
              onClick={handleLogout}
              startIcon={<DangerousIcon />}
              styles={{
                background: "none",
                color: "#3c1329",
                "&:hover": {
                  backgroundColor: "#fff2f2",
                  fontWeight: 800,
                },
                mr: 2,
                width: "180px",
              }}
            />
            <Button
              label="ÅTERKOM"
              variant="text"
              onClick={handleLogout}
              startIcon={<PhoneCallbackIcon />}
              styles={{
                background: "none",
                color: "#3c1329",
                "&:hover": {
                  backgroundColor: "#fff2f2",
                  fontWeight: 800,
                },
                mr: 2,
                width: "180px",
              }}
            />
            <Button
              label="NEJ"
              variant="text"
              onClick={handleLogout}
              startIcon={<SpeakerNotesOffIcon />}
              styles={{
                background: "none",
                color: "#3c1329",
                "&:hover": {
                  backgroundColor: "#fff2f2",
                  fontWeight: 800,
                },
                mr: 2,
                width: "180px",
              }}
            />
            <Button
              label="ORDER"
              variant="text"
              onClick={handleLogout}
              startIcon={<AddShoppingCartIcon />}
              styles={{
                background: "none",
                color: "#3c1329",
                "&:hover": {
                  backgroundColor: "#fff2f2",
                  fontWeight: 800,
                },
                mr: 2,
                width: "180px",
              }}
            />
          </Box>
          <Box>
            <Chip
              variant="outlined"
              label="Ringing"
              styles={{ borderColor: "blue", color: "blue", mr: 2 }}
            />
            <Chip label="00:00:00" styles={{ mr: 2 }} icon={<TimerIcon />} />
            <IconButton
              Icon={<CallEndIcon />}
              onClick={handleLogout}
              styles={{
                backgroundColor: "#e55",
                "&:hover": {
                  backgroundColor: "red", // Darker red on hover
                },
                borderRadius: "100%",
              }}
            />
          </Box>
        </Item>
      </Grid>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid size={4}>
          <Item>
            <Box
              sx={{
                backgroundColor: "#3c1329",
                color: "#fff",
                fontSize: "1rem",
                borderRadius: "0.2rem 0.2rem 0 0",
                p: 1,
                m: -2,
                fontWeight: 500,
              }}
            >
              Adressuppgifter
            </Box>
            <Box
              sx={{
                p: 5,
              }}
            >
              <AddressDetailsForm />
            </Box>
          </Item>
          <Box sx={{ pt: 2 }}></Box>
          <Item>
            <Box
              sx={{
                backgroundColor: "#3c1329",
                color: "#fff",
                fontSize: "1rem",
                borderRadius: "0.2rem 0.2rem 0 0",
                p: 1,
                m: -2,
                fontWeight: 500,
              }}
            >
              Kommentar
            </Box>
            <Box
              sx={{
                p: 5,
              }}
            >
              <CommentField />
            </Box>
          </Item>
        </Grid>
        <Grid size={4}>
          <Item sx={{ height: "100%", background: "white" }}>
            <Box
              sx={{
                backgroundColor: "#3c1329",
                color: "#fff",
                fontSize: "1rem",
                borderRadius: "0.2rem 0.2rem 0 0",
                p: 1,
                m: -2,
                fontWeight: 500,
              }}
            >
              Kampanjfält
            </Box>
          </Item>
        </Grid>
        <Grid
          size={4}
          sx={{
            height: "83vh", // Enforce full viewport height for the parent
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Item
            sx={{ height: "50%", background: "white", overflowY: "scroll" }}
          >
            <Box
              sx={{
                backgroundColor: "#3c1329",
                color: "#fff",
                fontSize: "1rem",
                borderRadius: "0.2rem 0.2rem 0 0",
                p: 1,
                m: -2,
                fontWeight: 500,
              }}
            >
              Kampanjinstruktion
            </Box>
          </Item>
          <Box sx={{ pt: 2 }}></Box>
          <Item sx={{ height: "48%", background: "white" }}>
            <Box
              sx={{
                backgroundColor: "#3c1329",
                color: "#fff",
                fontSize: "1rem",
                borderRadius: "0.2rem 0.2rem 0 0",
                p: 1,
                m: -2,
                fontWeight: 500,
              }}
            >
              Statistik
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
