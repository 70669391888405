import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import CampaignSelector from "../Dashboard/CampaignSelector";
import PostCallActionSelector from "../Dashboard/PostCallActionSelector"; // Assuming this is your custom dropdown
import { ReactComponent as Logo } from "../../assets/ud-logo.svg";
import Button from "./Button"; // Path to your SVG file
import TtyIcon from "@mui/icons-material/Tty";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Chip from "../Shared/Chip";

const Navbar: React.FC = () => {
  const handleLogout = () => {
    console.log("Logout clicked");
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#fff", overflow: "hidden" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          padding: 0,
          margin: -1,
        }}
      >
        {/* SVG Logo */}
        <Box sx={{ display: "flex", alignItems: "center", minWidth: 0 }}>
          <Logo height="40px" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <CampaignSelector />
          <Box
            sx={{ display: "flex", alignItems: "center", minWidth: 0, pr: 2 }}
          >
            <Button
              label="SKAPA KUND"
              onClick={handleLogout}
              startIcon={<PersonAddIcon />}
              styles={{
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#3c1329", // Darker red on hover
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", minWidth: 0 }}>
            <Button
              label="STARTA DIALER"
              onClick={handleLogout}
              startIcon={<TtyIcon />}
              styles={{
                backgroundColor: "#e55",
                "&:hover": {
                  backgroundColor: "#3c1329", // Darker red on hover
                },
              }}
            />
          </Box>
        </Box>

        {/* Logout Button */}
        <Box sx={{ display: "flex", alignItems: "center", minWidth: 0 }}>
          <Chip
            label="Ehtisham Arshad"
            styles={{ mr: 2 }}
            icon={<SupportAgentIcon />}
          />
          <PostCallActionSelector />
          <Button
            label="LOGOUT"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            styles={{
              backgroundColor: "#3c1329",
            }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
