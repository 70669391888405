import React from "react";
import { TextField } from "@mui/material";

const CommentField: React.FC = () => {
  return (
    <TextField
      label="Add a Comment"
      multiline
      fullWidth
      minRows={4}
      maxRows={6}
      margin="normal"
    />
  );
};

export default CommentField;
