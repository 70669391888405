import React from "react";
import Box from "@mui/material/Box";
import Dropdown from "../Shared/Dropdown";

interface Option {
  id: number;
  name: string;
}

const options: Option[] = [
  { id: 1, name: "Option A" },
  { id: 2, name: "Option B" },
];

const PostCallActionSelector: React.FC = () => {
  const [selectedOption, setSelectedOption] = React.useState<Option>(
    options[0]
  );

  return (
    <Box marginBottom={2} marginTop={2}>
      <Dropdown
        selectedItem={selectedOption}
        setSelectedItem={setSelectedOption}
        items={options}
        getItemLabel={(item) => item.name} // Use `name` as label
        getItemValue={(item) => item.id} // Use `id` as value
        onSelect={(item) => console.log(`Selected Option:`, item)}
        label="Efter fetta samtal"
        selectStyles={{ width: 150 }}
      />
      ;
    </Box>
  );
};

export default PostCallActionSelector;
