import React from "react";
import { TextField, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

const AddressDetailsForm: React.FC = () => {
  return (
    <form>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid size={6}>
          <TextField size="small" label="Fornamn" fullWidth margin="normal" />
        </Grid>
        <Grid size={6}>
          <TextField size="small" label="Afternamn" fullWidth margin="normal" />
        </Grid>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid size={6}>
          <TextField size="small" label="Adress" fullWidth margin="normal" />
        </Grid>
        <Grid size={6}>
          <TextField size="small" label="Postnr." fullWidth margin="normal" />
        </Grid>
      </Box>

      <TextField size="small" label="c/o" fullWidth margin="normal" />

      <TextField size="small" label="Stad" fullWidth margin="normal" />
      <Box sx={{ fontWeight: 800, textAlign: "left" }}>
        <p>Telefonnummer: </p>
        <p>Adress-id: </p>
        <p>Senaste Status: </p>
      </Box>
    </form>
  );
};

export default AddressDetailsForm;
