import React from "react";
import { Chip as MUIChip, styled, SxProps, Theme, Avatar } from "@mui/material";

interface ChipProps {
  onClick?: () => void;
  styles?: SxProps<Theme>;
  avatarContent?: React.ReactElement; // Specifically accept ReactElement (e.g., icons or images)
  label?: string; // Text to display on the chip
  variant?: "filled" | "outlined";
  icon?: React.ReactElement;
}

const StyledChip = styled(MUIChip)(({ theme }) => ({
  color: "#000",
  padding: "8px",
}));

const Chip: React.FC<ChipProps> = ({
  onClick,
  styles,
  avatarContent,
  label,
  icon,
  variant,
}) => {
  const renderAvatar = (): React.ReactElement | undefined => {
    if (avatarContent) {
      return <Avatar>{avatarContent}</Avatar>;
    }
    return undefined; // No avatar
  };

  return (
    <StyledChip
      avatar={avatarContent ? renderAvatar() : undefined}
      label={label || "Default Label"}
      sx={styles}
      onClick={onClick}
      icon={icon}
      variant={variant ? variant : "filled"}
    />
  );
};

export default Chip;
