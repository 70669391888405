import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SxProps, Theme } from "@mui/material";

interface SelectorProps<T> {
  selectedItem: T;
  setSelectedItem: React.Dispatch<React.SetStateAction<T>>;
  items: T[];
  getItemLabel: (item: T) => React.ReactNode;
  getItemValue?: (item: T) => string | number;
  onSelect?: (item: T) => void;
  selectStyles?: SxProps<Theme>;
  menuItemStyles?: React.CSSProperties;
  label: string;
}

const Dropdown = <T extends unknown>({
  selectedItem,
  setSelectedItem,
  items,
  getItemLabel,
  getItemValue,
  onSelect,
  selectStyles,
  menuItemStyles,
  label,
}: SelectorProps<T>) => {
  const handleSelection = (item: T) => {
    setSelectedItem(item);
    if (onSelect) {
      onSelect(item);
    }
  };

  return (
    <FormControl
      sx={{
        m: 1,
        backgroundColor: "white", // White background for the label area
        borderRadius: "4px", // Ensure proper border radius
      }}
      size="small"
      variant="outlined"
    >
      <InputLabel
        id="dropdown-label"
        sx={{
          backgroundColor: "white", // White background behind the label
          padding: "0 4px", // Padding to prevent text overlap
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        value={
          getItemValue
            ? getItemValue(selectedItem)
            : (selectedItem as unknown as string)
        }
        onChange={(e) => {
          const selected = items.find((item) =>
            getItemValue
              ? getItemValue(item) === e.target.value
              : item === e.target.value
          );
          if (selected) {
            handleSelection(selected);
          }
        }}
        fullWidth
        sx={{
          ...selectStyles,
          borderRadius: "4px", // Match border radius for consistency
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={
              getItemValue ? getItemValue(item) : (item as unknown as string)
            }
            sx={{ ...menuItemStyles }}
          >
            {getItemLabel(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
