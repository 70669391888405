import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const App: React.FC = () => {
  const theme = createTheme({
    palette: {
      mode: "light", // or "dark" based on your preference
      background: {
        default: "#f3ede6", // Set the default background color for the app
        paper: "#ffffff", // Set the background color of Paper components (like Dialogs, Cards, etc.)
      },
    },
    typography: {
      // Your typography settings here
      // testing webhook again
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline provides some default styles, including setting background */}
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
