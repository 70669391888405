import React from "react";
import { Button as MUIButton, styled, SxProps, Theme } from "@mui/material";

interface ButtonProps {
  label?: string;
  onClick: () => void;
  styles?: SxProps<Theme>; // Accept Material-UI `sx` styles for customization
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: "text" | "contained" | "outlined";
}

const StyledButton = styled(MUIButton)(({ theme }) => ({
  backgroundColor: "#3c1329", // Red background
  color: "#fff", // White text
  "&:hover": {
    backgroundColor: "#e55", // Darker red on hover
  },
  padding: "8px 16px", // Standard padding
  minWidth: "auto", // Avoid the default minimum width
}));

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  styles,
  startIcon,
  endIcon,
  variant,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      sx={styles}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
    >
      {label}
    </StyledButton>
  );
};

export default Button;
